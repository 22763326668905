<template>
  <section class="hero is-cararra is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container">
        <p class="greetings">Hey there!</p>
        <p class="title has-text-black m-0">
          I'm Mark
        </p>
        <p
          class="job-description has-background-black has-text-white mb-6 px-2"
        >
          Software Engineer from
          <span class="has-text-link">Philippines</span>.
        </p>
        <router-link to="/about" class="button is-primary is-large">
          KNOW MORE
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.greetings {
  text-align: left;
  font-size: 24px;
  letter-spacing: 3.84px;
  margin-bottom: -20px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    background-color: $cararra;
    width: fit-content;
    padding: 0 0.3rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1023px) {
    background-color: $cararra;
    width: fit-content;
    padding: 0 0.5rem;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1215px) {
    background-color: $cararra;
    width: fit-content;
    padding: 0 0.5rem;
  }

  @media only screen and (min-width: 1216px) and (max-width: 1407px) {
    background-color: $cararra;
    width: fit-content;
    padding: 0 0.5rem;
  }
}

.title {
  font-size: 100px;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 60px;
    margin-top: 0.5rem !important;
    background-color: $cararra;
    width: fit-content;
    padding: 0 0.1rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1023px) {
    margin-top: 0.5rem !important;
    background-color: $cararra;
    width: fit-content;
    padding: 0 0.3rem;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1215px) {
    margin-top: 0.5rem !important;
    background-color: $cararra;
    width: fit-content;
    padding: 0 0.3rem;
  }

  @media only screen and (min-width: 1216px) and (max-width: 1407px) {
    margin-top: 0.5rem !important;
    background-color: $cararra;
    width: fit-content;
    padding: 0 0.3rem;
  }
}

.job-description {
  font-size: 40px;
  width: fit-content;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 1rem !important;
    padding: 0 0.3rem !important;
  }
}

@media only screen and (max-width: 768px) {
  a.button.is-primary.is-large {
    font-size: 1rem;
  }
}
</style>
